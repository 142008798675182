<div class="col-xl">
    <form [formGroup]="unitForm" id="formUnit" (ngSubmit)="addUnit()">
        <div class="card mb-4">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h5 class="mb-0">Datos de la Unidad</h5>
            </div>
            <div class="card-body">
                <div class="mb-3">
                    <div class="row">
                        <div class="col-lg-3 col-xs-12">
                            <label class="form-label" for="basic-default-company">Unidad*</label>
                            <div class="input-group input-group-merge">
                                <select class="form-control" id="basic-default-company" [(ngModel)]="formModelData.type" [ngClass]="unitForm.controls['type'].errors  && !unitForm.controls['type'].pristine ? 'is-invalid' : ''"
                                    formControlName="type">
                                    <option value="">Seleccione una opción</option>
                                    <option value="1">Tipo Partner</option>
                                    <option value="2">Tipo Panel</option>
                                    <option value="3">Tipo crafter</option>
                                    <option value="4">RAM</option>
                                    <option value="5">Torton</option>
                                    <option value="6">Tracto</option>
                                </select>
                                <!-- <input type="text" class="form-control" id="basic-default-company" [(ngModel)]="formModelData.type" [ngClass]="unitForm.controls['type'].errors  && !unitForm.controls['type'].pristine ? 'is-invalid' : ''"
                                    formControlName="type"> -->
                                <div class="invalid-feedback" *ngIf="unitForm.controls['type'].errors  && !unitForm.controls['type'].pristine">
                                    El campo unidad es obligatorio
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-xs-12">
                            <label class="form-label" for="basic-default-company">Nombre de unidad*</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" id="basic-default-company" [(ngModel)]="formModelData.unit" [ngClass]="unitForm.controls['unit'].errors  && !unitForm.controls['unit'].pristine ? 'is-invalid' : ''"
                                    formControlName="unit">
                                <div class="invalid-feedback" *ngIf="unitForm.controls['unit'].errors  && !unitForm.controls['unit'].pristine">
                                    El campo nmobre unidad es obligatorio
                                </div>
                            </div>
                            
                        </div>
                        <div class="col-lg-3 col-xs-12">
                            <label class="form-label" for="basic-default-company">Placas*</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" id="basic-default-company" [(ngModel)]="formModelData.plates" [ngClass]="unitForm.controls['plates'].errors  && !unitForm.controls['plates'].pristine ? 'is-invalid' : ''"
                                formControlName="plates">
                                <div class="invalid-feedback" *ngIf="unitForm.controls['plates'].errors  && !unitForm.controls['plates'].pristine">
                                    El campo placas es obligatorio
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-4">
            <div class="card-body text-center">
                <button type="submit" class="btn btn-primary me-sm-3 me-1">Guardar Unidad</button>
            </div>
        </div>
    </form>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.7)" size="medium" color="#fff" type="ball-atom">
    <p style="font-size: 20px; color: white">Espere un momento...</p>
</ngx-spinner>