import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { UnitService } from 'src/app/services/unit.service';
import { UtilsService } from 'src/app/services/utils.service';

declare const $: any;

@Component({
    selector: 'app-unit-add',
    templateUrl: './unit-add.component.html',
    styleUrls: ['./unit-add.component.css']
})
export class UnitAddComponent {
    constructor(
        private fb: FormBuilder,
        private utils: UtilsService,
        private spinner: NgxSpinnerService,
        private apiUnit: UnitService,
    ) { }

    formModelData = {
        unit: null,
        plates: null,
        type: ""
    }

    unitForm!: FormGroup

    ngOnInit(): void {

        this.unitForm = this.fb.group({
            type: ['', Validators.required],
            unit: ['', Validators.required],
            plates: ['', Validators.required]
        })
    }

    addUnit() {
        if (this.unitForm.valid) {
            this.spinner.show()
            this.apiUnit.addUnit(this.formModelData).subscribe((resp: any) => {
                this.spinner.hide()
                $("#formUnit")[0].reset()
                this.utils.alertSwal({ title: "Exitoso", text: resp.msg, icon: "success" })

            }, (error) => {
                this.spinner.hide()
                this.utils.alertSwal({ title: "Error!", text: "Se ha presentado un error al insertar conductor", icon: "error" })
            })
        } else {

            this.utils.alertSwal({ title: "Advertencia", text: "Verifique que la información se encuentre completa.", icon: "warning" })
        }
    }

    get registerFormControl() {
        return this.unitForm.controls;
    }
}
